<template>
	<div>
		<Toast />
		<div class="sidebar">
			<div class="logo">
				<img class="small" src="../../assets/teleradio-sm.png" alt="">
				<img class="full" src="../../assets/teleradio.png" alt="">
			</div>
			<div class="snav-container">
				<div class="snav">
					<div class="title">Menu</div>
					<ul>
						<li>
							<router-link to="/agent/dashboard">
								<i class="fa fa-dashboard"></i>
								<span>Dashboard</span>
							</router-link>
						</li>
						<li>
							<router-link to="/agent/xray-reports">
								<i class="fa fa-flask"></i>
								<span>Xray Reports</span>
							</router-link>
						</li>
						<li>
							<router-link to="/agent/ecg-reports">
								<i class="fa fa-solid fa-heart-pulse"></i>
								<span>ECG Reports</span>
							</router-link>
						</li>
						<li>
							<router-link to="/agent/pft-reports">
								<i class="fa fa-solid fa-lungs"></i>
								<span>PFT Reports</span>
							</router-link>
						</li>
						<li>
							<router-link to="/agent/eeg-reports">
								<i class="fa fa-solid fa-brain"></i>
								<span>EEG Reports</span>
							</router-link>
						</li>

						
						<li>
							<router-link to="/agent/center-details">
								<i class="fa fa-hospital-o"></i>
								<span class="text">Centers</span>
							</router-link>
						</li>
						<li>
							<router-link to="/agent/xray-uploading">
								<i class="fa fa-file-excel-o"></i>
								<span class="text">X-Ray Uploading</span>
							</router-link>
						</li>
						<li>
							<router-link to="/agent/my-received">
								<i class="fa fa-money"></i>
								<span class="text">My Received</span>
							</router-link>
						</li>
						<li>
							<router-link @click.prevent="logOut" to="/">
								<i class="fa fa-lock"></i>
								<span class="text">Logout</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="top-container">
			<header>
				<div class="rnav">
					<div title="Support">
						<span class="text-success" @click="support = {}; ticketRaised = false; addModal.show();">
							<i class="fa-solid fa-headset"></i>
						</span>
					</div>
					<div @click="logOut" title="Logout">
						<i class="fa fa-lock"></i>
					</div>
					<div title="Speed check">
						<a href="https://fast.com" target="_balnk">
							<i class="fa fa-signal"></i>
						</a>
					</div>
					<div>
						<a target="_balnk" title="Contact coordinator" href="https://api.whatsapp.com/send?phone=8902035547">
							<i class="fa fa-whatsapp"></i>
						</a>
					</div>
					<div>
						<b>{{ agentName }}</b>
					</div>
				</div>
				<div class="tnav">
					<!-- <ul class="clearfix">
						<li>
							<router-link to="./">Dashboard</router-link>
						</li>
					</ul> -->
				</div>
			</header>
			<div class="content">
				<router-view></router-view>
			</div>
		</div>




		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title clearfix" style="width: 100%;">
								<div class="pull-left">
									<i class="fa-solid fa-headset"></i> Support
								</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="clearfix">
								<div class="pull-right" style="margin-left: 100px; margin-bottom: 10px;">
									<a class="text-success" href="https://wa.me/message/CCIEK2E6ALTCI1" target="_balnk" style="font-size: 18px; text-decoration: none;">
										<i class="fa fa-whatsapp" style="font-size: 24px;"></i> WhatsApp Support
									</a>
								</div>
							</div>

							<div v-if="!ticketRaised">
								<div class="form-group ui-float-label">
									<input type="tel" class="form-control" v-model="support.center_mobile_no" placeholder=" " />
									<label>Contact Number (Number to receive call upon)</label>
								</div>
								
								<div class="form-group ui-float-label">
									<textarea type="text" class="form-control" v-model="support.reason_description" 
										 placeholder=" " ></textarea>
									<label>Comment (Optional)</label>
								</div>
							</div>
							<div v-else style="font-size: 17px;">
								One of our support staff will call you shortly.
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" v-if="!ticketRaised" class="btn btn-primary" :disabled="ticketRaising">{{ ticketRaising ? "Raising..." : "Raise Ticket"}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>



	</div>
</template>
<script>
import { Modal } from 'bootstrap'
import Toast from 'primevue/toast';
export default {
	components: {
		Toast
	},
	data() {
		return {
			support: {},
			ticketRaised: false,
			ticketRaising: false,
			openDropdown: '',
			activeLink: '',
			agentName: '',
		}
	},
	beforeRouteEnter(req, res, next) {
		if (!window.localStorage.agent_access_token) return next("/agent-login");
		next();
	},
	async mounted() {
		this.addModal = new Modal(this.$refs.addModal, { backdrop: 'static', keyboard: false });
	},
	methods: {
		enter(){
			let u = JSON.parse(window.localStorage.agent_access_token);
			this.agentName = u.name;
		},
		logOut() {
			delete window.localStorage.agent_access_token;
			this.$router.push("/agent-login");
		},
		async putRecord(){
			if(this.ticketRaising) return;
			this.ticketRaising = true;
			
			this.support.support_reason = 'Book Call Back';
			let center = JSON.parse(localStorage.agent_access_token);

			let fd = {
				module: 'TR',
				support_reason: this.support.support_reason,
				reason_description: this.support.reason_description ? this.support.reason_description : "",
				center_id: center.id,
				center_name: "Agent",
				owner_name: center.name,
				center_mobile_no: center.phone,
				report_type: this.support.report_type ? this.support.report_type : 'null',
				report_id: 0
			};

			if(this.support.support_reason == 'Book Call Back' && this.support.center_mobile_no){
				fd.center_mobile_no = this.support.center_mobile_no;
			}
			

			await this.post('https://support.snafindia.in/api/tickets/add', fd)

			this.ticketRaised = true;
			this.ticketRaising = false;
		}
	},
}
</script>
<style scoped>
.top-container {
	min-height: 100vh;
	/* background: rgb(235 237 239); */
	background: rgb(247 247 247);
	padding-left: 56px;
}

header {
	background: #fff;
	box-shadow: 0px 4px 8px #ccc;
	border-bottom: 1px solid #ccc;
	height: 56px;

}


.rnav {
	float: right;
	padding-right: 10px;
}

.rnav>div {
	color: #555;
	padding: 15px;
	cursor: pointer;
	font-size: 18px;
	position: relative;
	float: right;
}

.rnav>div:hover {
	color: #333;
}

.rnav>div a {
	color: #555;
}

.tnav {
	float: left;
}

.tnav ul,
.tnav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.tnav ul {
	padding-left: 15px;
}

.tnav li {
	float: left;
	padding: 15px;
}

.tnav li a {
	text-decoration: none;
	padding: 5px;
	font-size: 14px;
	color: rgba(0, 0, 21, .5);
	cursor: pointer;
}

.tnav li a:hover,
.tnav li a.router-link-exact-active {
	color: rgba(0, 0, 21, .7);
}

/* width */
.sidebar ::-webkit-scrollbar {
	width: 8px;
	border-radius: 20px;
}

/* button */
.sidebar ::-webkit-scrollbar-button {
	background: #222;
	height: 0;
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 8px;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
	background: #aaa;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
}

/* The track NOT covered by the handle.*/
.sidebar ::-webkit-scrollbar-track-piece {
	background: rgba(0, 0, 0, 0);
}

/* Corner */
.sidebar ::-webkit-scrollbar-corner {
	background: rgba(0, 0, 0, 0);
}

/* Resizer */
.sidebar ::-webkit-resizer {
	background: rgba(0, 0, 0, 0);
}

.sidebar {
	width: 56px;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5;
	overflow: hidden;
	/* background: rgb(60 75 100); */
	background: rgb(236 237 240);
	transition: width .3s;
	font-size: .875rem;
	border-right: 1px solid #ddd;
	box-shadow: 1px 0 14px #ccc;
}

.sidebar:hover {
	width: 256px;
}

.snav-container {
	height: 100%;
	padding-bottom: 56px;
}

.sidebar .snav {
	overflow-y: hidden;
}

.sidebar:hover .snav {
	height: 100%;
	overflow-y: overlay;
}

.sidebar .snav ul,
.sidebar .snav li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.sidebar .snav li {
	overflow: hidden;
	font-size: .875rem;
	font-weight: 400;
}


.sidebar .snav li a {
	overflow: hidden;
	/* color: rgba(255,255,255,.8); */
	color: #555;
	height: 56px;
	min-width: 256px;
	padding: 18px;
	padding-right: 10px;
	transition: background .3s, color .3s;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.sidebar .snav li a:hover,
.sidebar .snav li a.router-link-exact-active {
	color: #fff;
	/* background: #321fdb; */
	background: rgb(239 151 26);
}

.sidebar .snav li a * {
	visibility: hidden;
}

.sidebar .snav li a .fa {
	font-size: 17px;
	margin-right: 15px;
	visibility: visible;
}

.sidebar .snav li a .fa-chevron-left {
	font-size: 12px;
	margin-top: 5px;
	transition: transform .3s;
}

.sidebar:hover .snav li a * {
	visibility: visible;
}

.sidebar .snav>ul>li>ul {
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s;
}

.sidebar:hover .snav>ul>li.open>ul {
	max-height: 900px;
}

.sidebar:hover .snav>ul>li.open>a .fa-chevron-left {
	transform: rotate(-90deg);
}

.sidebar .snav>ul>li>ul a {
	padding-left: 30px;
	/* background: rgb(81 98 126); */
	background: rgb(206 206 206);
}


.sidebar .title {
	text-transform: uppercase;
	color: rgba(255, 255, 255, .6);
	color: #000;
	height: 0px;
	overflow: hidden;
	transition: .3s;
}

.sidebar:hover .title {
	height: auto;
	padding: .75rem 1rem;
	margin-top: 1rem;
	font-size: 80%;
	font-weight: 700;
}

.logo {
	/* background: rgb(236 237 240); */
	background: rgb(206 206 206);
	height: 56px;
	position: relative;
	padding: 8px;
}

.logo img {
	display: block;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
}

.logo .small {
	display: block;
}

.logo .full {
	display: none;
}

.sidebar:hover .logo .full {
	display: block;
}

.sidebar:hover .logo .small {
	display: none;
}
</style>